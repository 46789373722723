import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';

export const SocialIcon = styled.img`
  height: 50px;
  margin: 0 5px;
  float: left;
  cursor: pointer;
  ${mediaMax('small', css`
    height: 40px;
  `)}
`;

export const SocialIconsContainer = styled.div`
  display: inline-block;
`;
