import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';

export const AppContainer = styled.div`
    position: relative;
    width: 100%;
    // height: 100%;
    touch-action: ${props => props.paintMode ? 'none' : 'auto'};
    z-index: 2;
`;

export const Me = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 8pt;
    color: #666;
    a {
      color: #FF288D;
    }
    z-index: 1;
`;
