import React from 'react';
import { HeadImg, H1, P } from './styles';
import Head from 'assets/Images/ich.gif';
import { useWindowStore } from 'services/WindowService';

const Info = () => {
  const isMobile = useWindowStore(state => state.isMobile);
  return (
    <>
        <HeadImg src={Head} draggable="false"/>
        <H1>Jurek {isMobile && <br/>} Malottke</H1>
        <P>
          comic book artist, illustrator, returner of books<br/>
          from Bad Breisig, Germany<br/>
          <br/>
          <a href="mailto:jurek@metagrundierung.com">Jurek@Metagrundierung.com</a><br/>
          
        </P>
    </>
  );
};

export default Info;
