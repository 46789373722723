import CanvasFreeDrawing from 'canvas-free-drawing';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { paintingApi } from 'services/PaintingService';
import { useWindowStore } from 'services/WindowService';
import { Canvas } from './styles';
import bgPattern from 'assets/Images/bgRaster.png';

const mobileHeightPlus = 120;

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

const PaintCanvas = ({width, height, paintColor, paintSize, toolIndex, clearToggle, saveInc, mobilePaintMode, ScrollAppContainer}) => {
  const isMobile = useWindowStore(state => state.isMobile);
  const canvasRef = useRef();
  const [internalHeight, setInternalHeight] = useState(height);

  const cfd = useMemo(()=>{
    if(canvasRef.current === undefined) return null;
    const cfd = new CanvasFreeDrawing({
      elementId: 'cfd',
      width,
      height,
      backgroundColor: [255, 255, 255],
    });
    cfd.configBucketTool({tolerance: 3});
    return cfd;
  }, [canvasRef.current])

  useEffect(()=>{
    if (cfd) cfd.setDrawingColor(hexToRgb(paintColor));
  }, [paintColor, cfd])

  useEffect(()=>{
    if (cfd) cfd.setLineWidth(paintSize);
  }, [paintSize, cfd])

  useEffect(()=>{
    if (!cfd) return;
    if (toolIndex === 0) {
      // Pencil
      cfd.context.globalCompositeOperation = "source-over";
      if (cfd.isBucketToolEnabled) cfd.toggleBucketTool();
    } else if(toolIndex === 1) {
      // Bucket
      cfd.context.globalCompositeOperation = "source-over";
      if (!cfd.isBucketToolEnabled) cfd.toggleBucketTool();
    } else if(toolIndex === 2) {
      // Eraser
      if (cfd.isBucketToolEnabled) cfd.toggleBucketTool();
      cfd.context.globalCompositeOperation = "destination-out";
      cfd.context.strokeStyle = "rgba(255,255,255,1)";
    }
  }, [toolIndex, cfd])

  const onCanvasScroll = (e) => {
    ScrollAppContainer(e.nativeEvent.deltaY);
  }

  useEffect(()=>{
    //if (isMobile) {
      if(height > internalHeight) setInternalHeight(height + mobileHeightPlus);
    //} else {
      //setInternalHeight(height);
    //}
  }, [height]);

  useEffect(()=>{
    if (!cfd) return;
    if (isMobile && !mobilePaintMode) {
      cfd.disableDrawingMode();
    } else {
      cfd.enableDrawingMode();
    }
  }, [mobilePaintMode, isMobile, cfd]);

  useEffect(()=>{
    if (canvasRef.current) canvasRef.current.getContext('2d').clearRect(0, 0, width, height);
  }, [clearToggle, canvasRef.current])

  const mouseDown = ()=>{
    if (toolIndex === 0) paintingApi.getState().setIsPainting(true);
  }
  const mouseUp = ()=>{paintingApi.getState().setIsPainting(false);}
  const mouseLeave = ()=>{paintingApi.getState().setIsPainting(false);}
  const mouseOut = ()=>{paintingApi.getState().setIsPainting(false);}
  const mouseOver = ()=>{paintingApi.getState().setIsPainting(false);}

  useEffect(()=>{
    if (cfd && saveInc > 0) {
      // Save
      const patternCanvas = document.createElement("canvas");
      const width = cfd.canvas.getAttribute("width"), height = cfd.canvas.getAttribute("height");
      patternCanvas.setAttribute("width", width); patternCanvas.setAttribute("height", height);
      const ctx = patternCanvas.getContext("2d");

      const img = new Image();
      img.src = bgPattern;
      const ptrn = ctx.createPattern(img, 'repeat'); // Create a pattern with this image, and set it to "repeat".
      ctx.fillStyle = ptrn;
      ctx.fillRect(0, 0, width, height);

      //ctx.rect(0, 0, width, height);
      //ctx.fill();
      ctx.drawImage(cfd.canvas, 0, 0);
      const link = document.createElement('a');
      const data = patternCanvas.toDataURL("image/png");
      link.href = data;
      link.download = 'JMpaint_'+saveInc+'.png';
      link.click();
      patternCanvas.remove();
    }
  }, [saveInc, cfd])
  return (
      <Canvas id='cfd' width={width} height={internalHeight} ref={canvasRef} onWheel={onCanvasScroll}
      onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseLeave={mouseLeave} onMouseOut={mouseOut} onMouseOver={mouseOver} />
  );
};

export default PaintCanvas;
