import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';

export const PublicationContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 12pt;
  ${mediaMax('small', css`
    font-size: 10pt;
  `)}
`;

export const TextRow = styled.div`
  float: left;
  width: calc(100% - 180px);
  padding-top: 35px;
  margin-left: 20px;
  ${mediaMax('small', css`
  padding-top: 0;
  `)}
`;

export const Image = styled.img`
  float: left;
  width: 160px;
  display: block;
  ${mediaMax('small', css`
    width: 110px;
  `)}
`;

export const Title = styled.span`
  font-weight: 900;
  
`;

export const Year = styled.span`
  font-weight: normal;
`;

export const Info = styled.p`
  margin: 0;
  font-weight: normal;
`;

export const OrderButton = styled.a`
  display: inline-block;
  cursor: pointer;
  color: white;
  background-color: #FF288D;
  font-weight: 900;
  padding: 10px 15px;
  margin-top: 15px;
  text-decoration: none;
`;

export const PageNumberContainer = styled.div`
  text-align: center;
`;

export const PageNumber = styled.div`
  display: inline-block;
  cursor: ${props => props.active ? 'default' : 'pointer'};
  color: ${props => props.active ? 'white' : 'black'};
  background-color: ${props => props.active ? '#FF288D' : 'none'};
  //font-weight: 900;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 50%;
  text-decoration: ${props => props.active ? 'none' : 'underline'};
`;
