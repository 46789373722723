import { css } from 'styled-components';

export const breakPoints = {
  small: { min: 0, max: 768 },
  medium: { min: 769, max: 1365 },
  large: { min: 1366, max: 99999 },
};

export const mediaMin = (breakpoint, style) => css`
  @media only screen and (min-width: ${breakPoints[breakpoint].min}px) {
    ${style}
  }
`;

export const mediaMax = (breakpoint, style) => css`
  @media only screen and (max-width: ${breakPoints[breakpoint].max}px) {
    ${style}
  }
`;

export const mediaMaxPx = (pixel, style) => css`
  @media only screen and (max-width: ${pixel}px) {
    ${style}
  }
`;

export const mediaMinMax = (breakpointMin, breakpointMax, style) => css`
  ${mediaMin(breakpointMin, mediaMax(breakpointMax, style))}
`;

const small = style => css`
  ${mediaMinMax('small', 'small', style)}
`;

const medium = style => css`
  ${mediaMinMax('medium', 'medium', style)}
`;

const large = style => css`
  ${mediaMinMax('large', 'large', style)}
`;

export const vp = {
  small,
  medium,
  large,
};
