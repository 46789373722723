import React from 'react';
import { SpecialWindow, ColorCircle, ToolIcon, ToolTitle, SizeOutline, SizeCircle, ToolContainer, ToolImage, DivisionLine } from './styles';
import { CloseIcon, Header, Title } from 'components/Window/styles';
import PencilImage from './Images/Pencil.png';
import BucketImage from './Images/Bucket.png';
import EraserImage from './Images/Eraser.png';
import TrashImage from './Images/Trash.png';
import DownloadImage from './Images/Download.png';
import { useWindowStore } from 'services/WindowService';

const toolImages = [PencilImage, BucketImage, EraserImage];

const JMPaint = (props) => {
  const isMobile = useWindowStore(state => state.isMobile);
  return (
    <SpecialWindow mobilePaintMode={props.mobilePaintMode}>
      <Header onClick={()=>{if(isMobile) props.setMobilePaintMode(false)}}>
        <Title>Paint</Title>
        {isMobile && <CloseIcon open>
          <div id='one'/>
          <div id='two'/>
        </CloseIcon>}
      </Header>
      <ToolContainer onClick={props.onColorClick}>
        <ToolTitle>Color</ToolTitle>
        <ToolIcon>
          <ColorCircle color={props.paintColor} />
        </ToolIcon>
      </ToolContainer>
      <ToolContainer onClick={props.onSizeClick}>
        <ToolTitle>Size</ToolTitle>
        <ToolIcon>
          <SizeOutline color={props.paintColor}>
            <SizeCircle color={props.paintColor} size={props.brushSize} />
          </SizeOutline>
        </ToolIcon>
      </ToolContainer>
      <ToolContainer onClick={props.onToolClick}>
        <ToolTitle>Tool</ToolTitle>
        <ToolIcon style={{height: "45px", marginTop: "10px"}}>
          <ToolImage src={toolImages[props.toolIndex]} draggable="false" />
        </ToolIcon>
      </ToolContainer>
      <DivisionLine />
      <ToolIcon onClick={props.clearCanvas}>
        <ToolImage src={TrashImage} draggable="false" />
      </ToolIcon>
      <ToolIcon onClick={props.saveImage} noMargin>
        <ToolImage src={DownloadImage} draggable="false" />
      </ToolIcon>
    </SpecialWindow>
  );
};

export default JMPaint;
