import React, { useState } from 'react';
import { PreviewContainer, Preview, SelectedImage, SelectedPreview } from './styles';
import Img1 from './Images/1.png';
import Img2 from './Images/2.png';
import Img3 from './Images/3.png';
import Img4 from './Images/4.png';
import Img5 from './Images/5.png';
import Img6 from './Images/6.png';
import Img7 from './Images/7.png';
import Img8 from './Images/8.png';
import Img9 from './Images/9.png';
import Img10 from './Images/10.png';
import Img11 from './Images/11.png';
import Img12 from './Images/12.png';
import Img13 from './Images/13.png';
import Img14 from './Images/14.png';

const images = [
  {src: Img1}, {src: Img2}, {src: Img3}, {src: Img4}, {src: Img5}, {src: Img6}, {src: Img7}, 
  {src: Img8}, {src: Img9}, {src: Img10}, {src: Img11}, {src: Img12}, {src: Img13}, {src: Img14}
];

const renderPreviews = (selectedIndex, setIndex) => {
  const previews = images.map((i, index)=>{return (
  <PreviewContainer key={index}>
    <Preview src={i.src} onClick={()=>{setIndex(index)}} draggable="false" />
    {index === selectedIndex && <SelectedPreview />}
  </PreviewContainer>
  )});
  return previews;
}

const Gallery = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  return (
    <>
      {renderPreviews(selectedImageIndex, setSelectedImageIndex)}
      <SelectedImage src={images[selectedImageIndex].src} draggable="false" />
    </>
  );
};

export default Gallery;
