import styled, { css } from 'styled-components';
import pattern from 'assets/Images/halftone1.png';
import { mediaMax } from 'common/breakpoints';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: ${props => props.left};
  box-sizing: border-box;
  border: 2px solid black;
  box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.65);
  min-width: 100px;
  background-color: ${props=>(props.backgroundColor ? props.backgroundColor : "white")};;
  height: ${props=>(props.open ? "auto" : "30px")};
  width: ${props=>(props.open ? props.width ? props.width+"px" : "auto" : "300px")};
  opacity: 1;
  z-index: ${props=>props.zPos};
  padding: ${props=>(props.open ? "45px 20px 20px" : "0")};
  ${mediaMax(
    'small',
    css`
      display: inline-block;
      left: 0;
      float: left;
      position: relative;
      transform: ${props => props.hide ? props.hideTransform : 'none'} !important;
      ${props => props.fullWidthOnMobile && "width: calc(100% - 24px); top: 0;"}
      ${props => props.mobileMargin && "margin: "+props.mobileMargin+";"}
      // opacity: ${props => props.hide ? '0' : '1'};
      pointer-events: ${props => props.hide ? 'none' : 'all'};
      transition: transform 0.25s ${props => props.hide ? 'ease-in' : 'ease-out'};
      padding: ${props=>(props.open ? "38px 10px 15px" : "0")};
    `,
  )}
  overflow: hidden;
  pointer-events: ${props => props.noPointerEvents ? 'none' : 'all'};
`;

export const Header = styled.div`
  position: absolute;
  height: 30px;
  left: 0;
  right: 0;
  top: 0;
  background-image: url(${pattern});
  background-size: 8px;
  image-rendering: pixelated;
`;

export const Handle = styled.div`
    position: absolute;
    cursor: pointer;
    height: 100%;
    width: calc(100% - 30px);
`;

export const Title = styled.div`
  position: absolute;
  padding: 5px 15px;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 13px;
  font-weight: 600;
  user-select: none;
  text-transform: uppercase;
  ${mediaMax(
    'small',
    css`
      font-size: 11px;
      padding: 7px 9px;
    `,
  )}
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 3px;
  height: 20px;
  width: 20px;
  transform: ${props=>(props.open ? "rotate(45deg)" : "none")};
  >div {
    position: absolute;
    left: 50%;
    background-color: white;
    height: 100%;
    width: 3px;
  }
  >div#two {
    transform: rotate(90deg);
  }
`;
