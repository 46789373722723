import { mediaMax } from 'common/breakpoints';
import React from 'react';
import styled, { css } from 'styled-components';
import ToggleImg from './Images/Toggle.png'
import PencilImg from './Images/Pencil.png'

const Container = styled.div`
  position: fixed;
  right: 10px;
  top: 10px;
  width: 80px;
  cursor: pointer;
  transform: translateX(120%);
  backface-visibility: hidden;
  ${mediaMax('small', css`
    transform: ${props => props.hide ? 'translateX(120%)' : 'none'};
  `)}
  transition: transform 0.4s linear;
  z-index: 11;
`;

const Toggle = styled.img`
  ${mediaMax('small', css`
    transform: ${props => props.hide ? 'rotate(90deg)' : 'none'};
  `)}
  width: 100%;
  transition: transform 0.4s linear;
`;

const Pencil = styled.img`
  position: absolute;
  width: 36%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const MobilePaintToggle = ({mobilePaintMode, setMobilePaintMode}) => {
  return (
    <Container hide={mobilePaintMode} onClick={()=>{setMobilePaintMode(true)}} >
      <Toggle src={ToggleImg} hide={mobilePaintMode}/>
      <Pencil src={PencilImg} />
    </Container>
  );
};

export default MobilePaintToggle;
