import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';

export const PreviewContainer = styled.div`
  position: relative;
  float: left;
  cursor: pointer;
`;

export const Preview = styled.img`
  height: 50px;
  cursor: pointer;
  filter: grayscale(1);
  display: block;
  ${mediaMax('small', css`
    height: 40px;
  `)}
`;

export const SelectedPreview = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #FF288D;
`;

export const SelectedImage = styled.img`
  clear: both;
  float: left;
  width: 100%;
  display: block;
`;