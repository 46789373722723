import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { usePaintingStore } from 'services/PaintingService';
import { useWindowStore } from 'services/WindowService';
import { Container, Header, Title, CloseIcon, Handle } from './styles';

const Window = ({closed, position, width, backgroundColor, hide, hideTransform, fullWidthOnMobile, mobileMargin, title, zPos, children}) => {
  const [open, setOpen] = useState(!closed);
  const isMobile = useWindowStore(state => state.isMobile);
  const isPainting = usePaintingStore(state => state.isPainting);
  return (
    <Draggable defaultPosition={{x: 0, y: position.y}} disabled={isMobile || isPainting} handle=".handle">
      <Container open={open} width={width} left={position.x} backgroundColor={backgroundColor} hide={hide} hideTransform={hideTransform}
                fullWidthOnMobile={fullWidthOnMobile} mobileMargin={mobileMargin} zPos={zPos} noPointerEvents={isPainting}> 
          <Header onClick={()=>{if(isMobile && !open) setOpen(!open)}}>
              <Title>{title}</Title>
              <Handle className="handle" />
              <CloseIcon onClick={()=>{setOpen(!open)}} open={open}>
                  <div id='one'/>
                  <div id='two'/>
              </CloseIcon>
          </Header>
          {open && children}
      </Container>
    </Draggable>
  );
};

export default Window;
