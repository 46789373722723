import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';

export const HeadImg = styled.img`
  width: 160px;
  float: left;
  ${mediaMax('small', css`
    width: 95px;
  `)}
`;

export const H1 = styled.h1`
  display: inline-block;
  ${mediaMax('small', css`
    margin-top: 20px;
  `)}
  margin-left: 5px;
`;

export const P = styled.p`
  display: inline-block;
  margin-left: 5px;
`;
