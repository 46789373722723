import React, { useState, useEffect, useRef, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useWindowStore, windowApi } from 'services/WindowService';
import { AppContainer, Me } from './styles';
import Window from '../Window';
import Info from '../Info';
import Gallery from '../Gallery';
import Publications from '../Publications';
import SocialLinks from '../SocialLinks';
import ShopLink from '../ShopLink';
import LegalStuff from '../LegalStuff';
import JMPaint from '../JMPaint';
import PaintCanvas from '../PaintCanvas';
import MobilePaintToggle from '../MobilePaintToggle';
import { usePaintingStore } from 'services/PaintingService';

const colors = ['#FF288D', '#111111', '#30B667', '#0010AF']
const sizes = [0.3, 0.7, 1.0, 0.01]
const toolAmount = 3;

function Start() {
  useEffect(() => {
    windowApi.getState().init();
  }, []);
  const width = useWindowStore(state => state.width);
  const height = useWindowStore(state => state.height);
  const isMobile = useWindowStore(state => state.isMobile);

  const isPainting = usePaintingStore(state => state.isPainting);

  const [mobilePaintMode, setMobilePaintMode] = useState(false);
  const [colorIndex, setColorIndex] = useState(0);
  const [sizeIndex, setSizeIndex] = useState(0);
  const [toolIndex, setToolIndex] = useState(0);
  const [clearToggle, setClearToggle] = useState(false);
  const [saveInc, setSaveInc] = useState(0);

  const applicationDiv = useMemo(()=>{return document.getElementById("application");}, []);

  const switchColor = () => {
    let newIndex = colorIndex+1; if (newIndex >= colors.length) newIndex = 0;
    setColorIndex(newIndex);
  }
  const switchSize = () => {
    let newIndex = sizeIndex+1; if (newIndex >= sizes.length) newIndex = 0;
    setSizeIndex(newIndex);
  }
  const switchTool = () => {
    let newIndex = toolIndex+1; if (newIndex >= toolAmount) newIndex = 0;
    setToolIndex(newIndex);
  }
  const clearCanvas = () => {
    setClearToggle(!clearToggle);
  }
  const saveImage = () => {
    setSaveInc(saveInc + 1);
  }

  const ScrollAppContainer = (speed) => {
    //const applicationDiv = document.getElementById("application");
    //console.log(speed, applicationDiv.scrollTop);
    applicationDiv.scrollTop += speed;
  }

  return (
    <>
      <PaintCanvas width={width} height={height} paintColor={colors[colorIndex]} paintSize={sizes[sizeIndex] * 60}
                    toolIndex={toolIndex} clearToggle={clearToggle} saveInc={saveInc} mobilePaintMode={mobilePaintMode}
                    ScrollAppContainer={ScrollAppContainer}
      />
      <AppContainer paintMode={isMobile && mobilePaintMode}>
        <Window title="Hello" position={{x: '10%', y: 40}} width={800} fullWidthOnMobile
                mobileMargin="50px 12px 20px" hide={mobilePaintMode} hideTransform="translate(-120%, -50%) rotate(20deg)">
          <Info />
        </Window>
        <Window title="Shop" position={{x: '5%', y: 400}} mobilePosition={{x: 20, y: 300}} hideTransform="translate(-230%, -10%) rotate(-100deg)"
                mobileMargin="-30px 5px 0 25px" backgroundColor="#FF288D" hide={mobilePaintMode} zPos={2}>
          <ShopLink />
        </Window>
        <Window title="WWW" position={{x: '63%', y: 1060}} mobilePosition={{x: 100, y: 400}}hideTransform="translate(200%, 20%) rotate(100deg)"
                mobileMargin="5px 5px 0" hide={mobilePaintMode} zPos={1}>
          <SocialLinks />
        </Window>
        <Window title="gallery" position={{x: '35%', y: 260}} width={850} hideTransform="translate(130%, 20%) rotate(30deg)"
                fullWidthOnMobile mobileMargin="0 8px 40px" hide={mobilePaintMode}>
          <Gallery />
        </Window>
        <Window title="publications" position={{x: '12%', y: 800}} width={600} fullWidthOnMobile
                mobileMargin="20px 16px 40px" hide={mobilePaintMode} hideTransform="translate(-150%, 20%) rotate(-20deg)">
          <Publications />
        </Window>
        <Window title="legal notice (german)" position={{x: '15%', y: 1700}} width={500} closed fullWidthOnMobile
                mobileMargin="20px 12px 40px" hide={mobilePaintMode} hideTransform="translateX(-130%)">
          <LegalStuff />
        </Window>
        <JMPaint paintColor={colors[colorIndex]} onColorClick={switchColor}
                  brushSize={sizes[sizeIndex]} onSizeClick={switchSize}
                  toolIndex={toolIndex} onToolClick={switchTool}
                  clearCanvas={clearCanvas} saveImage={saveImage}
                  mobilePaintMode={mobilePaintMode} setMobilePaintMode={setMobilePaintMode}
        />
        <MobilePaintToggle mobilePaintMode={mobilePaintMode} setMobilePaintMode={setMobilePaintMode}/>
      </AppContainer>
      <Me>
        Website by <a href="https://www.instagram.com/valentinscheiner/" target="_blank">Valentin Scheiner</a>
      </Me>
    </>
  );
}

export default hot(module)(Start);
