import React from 'react';
import { Container, Shop } from './styles';

const ShopLink = () => {
  return (
      <Shop href='https://shop.metagrundierung.com/' target="_blank" draggable="false">SHOP</Shop>
  );
};

export default ShopLink;
