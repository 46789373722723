import React from 'react';
import { SocialIconsContainer, SocialIcon } from './styles';
import artstation from './Images/artstation.png';
import facebook from './Images/facebook.png';
import twitter from './Images/twitter.png';
import instagram from './Images/instagram.png';

const socialIcons = [
  {src: artstation, link:'https://www.artstation.com/metagrundierung'},
  {src: facebook, link:'https://www.facebook.com/Metagrundierung'},
  {src: twitter, link:'https://www.twitter.com/Metagrundierung'},
  {src: instagram, link:'https://www.instagram.com/metagrundierung'},
];

const renderSocialIcons = () => {
  const icons = socialIcons.map((si, index)=>{return (
    <a href={si.link} target="_blank" key={index}>
      <SocialIcon src={si.src} />
    </a>
  )});
  return icons;
}

const SocialLinks = () => {
  return (
    <>
      <SocialIconsContainer>
        {renderSocialIcons()}
      </SocialIconsContainer>
    </>
  );
};

export default SocialLinks;
