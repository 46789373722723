import React, { useState } from 'react';
import { PublicationContainer, Image, TextRow, Title, Year, Info, OrderButton, PageNumberContainer, PageNumber } from './styles';
import Img1 from './Images/VO_ASH.gif';
import Img2 from './Images/VO_BR.gif';
import Img3 from './Images/VO_DFDV.gif';
import Img4 from './Images/VO_CB11.gif';
import Img5 from './Images/VO_PH.gif';
import Img6 from './Images/VO_ZK.gif';

const pubsPerPage = 3;

const publications = [
  {
    title: 'Phantasmen',
    year: 2022,
    infos: ['story: Kai Meyer', '240 pages', 'Splitter Comics'],
    imgSrc: Img5,
    link: 'https://www.splitter-verlag.de/phantasmen.html'
  },
  {
    title: 'Zöliakie',
    year: 2022,
    infos: ['story: Michael Mikolajczak', '10 pages', 'Kult Comics'],
    imgSrc: Img6,
    link: 'https://kultcomics.net/gratis-comics-tag/376-zoeliakie-vater-sohn-tag.html'
  },
  {
    title: 'CAPTAIN BERLIN #11',
    year: 2020,
    infos: ['"Captain Berlin gegen', 'Captain Bootleg"', 'story: Jörg Buttgereit', '14 pages', 'Weissblech Comics'],
    imgSrc: Img4,
    link: 'https://weissblechcomics.com/onlineshop/captain_berlin_11'
  },
  {
    title: 'Sumi',
    year: 2020,
    infos: ['story: Michael Mikolajczak', '7 pages', 'Kult Comics'],
    imgSrc: Img2,
    link: 'https://kultcomics.net/'
  },
  {
    title: 'ASH #21',
    year: 2019,
    infos: ['"Kalevala"','story: Harald Havas', '6 pages', 'Australien Superheros'],
    imgSrc: Img1,
    link: 'https://www.austriansuperheroes.com/produkt/einzelhefte/'
  },
  {
    title: 'Das Fleisch der Vielen',
    year: 2018,
    infos: ['story: Kai Meyer', '65 pages', 'Splitter'],
    imgSrc: Img3,
    link: 'https://www.splitter-verlag.de/das-fleisch-der-vielen.html'
  },
];

const renderPublications = (page) => {
  const previews = publications.filter((p, index)=>index >= page*pubsPerPage && index < (page+1)*pubsPerPage).map((p, index)=>{return (
  <PublicationContainer key={index}>
    <a href={p.link} target="_blank">
      <Image src={p.imgSrc} draggable="false" />
    </a>
    <TextRow>
      <div style={{marginBottom: "15px"}}> <Title>{p.title}</Title> <Year>{p.year}</Year></div>
      {p.infos.map((i, index)=><Info key={index}>{i}</Info>)}
      <OrderButton href={p.link} target="_blank">Bestellen</OrderButton>
    </TextRow>
  </PublicationContainer>
  )});
  return previews;
}

const renderPageSelection = (pages, selectedPage, setPage) => {
  const numbers = [];
  for (let p = 0; p < pages; p++) {
    numbers.push(<PageNumber active={selectedPage===p} onClick={()=>{setPage(p)}}>{p+1}</PageNumber>);
  }
  return <PageNumberContainer>{numbers}</PageNumberContainer>;
}

const Publications = () => {
  const pages = Math.floor((publications.length - 1) / pubsPerPage) + 1;
  const [page, setPage] = useState(0);

  return (
    <>
      {renderPublications(page)}
      {pages > 1 && renderPageSelection(pages, page, setPage)}
    </>
  );
};

export default Publications;
