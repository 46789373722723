import styled from 'styled-components';

export const Shop = styled.a`
  float: left;
  color: white;
  font-size: 24pt;
  font-weight: 900;
  padding: 15px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
`;