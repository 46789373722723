import { mediaMax } from 'common/breakpoints';
import styled, { css } from 'styled-components';

export const SpecialWindow = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  box-sizing: border-box;
  border: 2px solid black;
  padding: 45px 20px 20px;
  box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.65);
  min-width: 100px;
  background-color: white;
  backface-visibility: hidden;
  ${mediaMax(
    'small',
    css`
      min-width: 85px;
      transform: ${props => props.mobilePaintMode ? 'none' : 'translateX(120%)'};
      padding: 40px 10px 15px;
    `,
  )}
  transition: transform 0.4s linear;
  z-index: 10;
`;

export const ToolContainer = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const ToolTitle = styled.div`
  font-size: 8pt;
  text-align: center;
  margin-bottom: 6px;
  user-select: none;
`;

export const ToolIcon = styled.div`
  cursor: pointer;
  text-align: center;
  margin-bottom:  ${props => props.noMargin ? "none" : "15px"};
`;

export const ColorCircle = styled.div`
  display: inline-block;
  background-color: ${props => props.color};
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

export const SizeOutline = styled.div`
  position: relative;
  display: inline-block;
  outline: 2px solid;
  outline-color: ${props => props.color};
  outline-offset: -1px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

export const SizeCircle = styled.div`
  position: absolute;
  background-color: ${props => props.color};
  transform: scale(${props => props.size > 0.05 ? props.size : 0.05});
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

export const ToolImage = styled.img``;

export const DivisionLine = styled.div`
  width: 100%;
  background-color: black;
  height: 1.5px;
  margin-bottom: 20px;
`;
